import LayoutConfig from '../config/layout'
import Container from '../app/Container/Main'
import PageContainer from '../app/Container/Page'

import Teacher from "../app/Pages/Report/Teacher";
import Order from "../app/Pages/Report/Order";
import Product from "../app/Pages/Report/Product";
import Customer from "../app/Pages/Report/Customer";
import Performance from "../app/Pages/Report/Performance";
import IndividualAssessment from "../app/Pages/Report/IndividualAssessment";

import CreateAccounting from "../app/Pages/Report/Accountings/Create";
import ShowAccounting from "../app/Pages/Report/Accountings/Show";
import DatatableContainer from "@/app/Container/Datatable";

export default {
    path: '/reports',
    name: 'api.reports.index',
    component: Container,
    meta: {
        auth: true,
        layout: LayoutConfig.full,
        title: 'Raporlar',
    },
    children: [
        {
            path: 'teachers',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Ogretmen Raporlari',
                breadcrumb: 'api.reports.teachers.index',
            },
            children: [
                {
                    name: 'api.reports.teachers.index',
                    path: '',
                    component: Teacher,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ogretmen Raporlari',
                        apiPoint: 'reports/teachers',
                    },
                }
            ]
        },
        {
            path: 'orders',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Siparis Raporlari',
                breadcrumb: 'api.reports.orders.index',
            },
            children: [
                {
                    name: 'api.reports.orders.index',
                    path: '',
                    component: Order,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Siparis Raporlari',
                        apiPoint: 'reports/orders',
                    },
                }
            ]
        },
        {
            path: 'products',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Urun Raporlari',
                breadcrumb: 'api.reports.products.index',
            },
            children: [
                {
                    name: 'api.reports.products.index',
                    path: '',
                    component: Product,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Urun Raporlari',
                        apiPoint: 'reports/products',
                    },
                }
            ]
        },
        {
            path: 'customers',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Musteri Raporlari',
                breadcrumb: 'api.reports.customers.index',
            },
            children: [
                {
                    name: 'api.reports.customers.index',
                    path: '',
                    component: Customer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Musteri Raporlari',
                        apiPoint: 'reports/customers',
                    },
                }
            ]
        },
        {
            path: 'individual-assessment-reports',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Bireysel Değerlendirme Raporları',
                breadcrumb: 'api.reports.individual_assessment_reports.index',
            },
            children: [
                {
                    name: 'api.reports.individual_assessment_reports.index',
                    path: '',
                    component: IndividualAssessment,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Bireysel Değerlendirme Raporları',
                        apiPoint: 'reports/individual-assessment-reports',
                    },
                }
            ]
        },
        {
            path: 'performances',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Ogretmen Performans Raporlari',
                breadcrumb: 'api.reports.performances.index',
            },
            children: [
                {
                    name: 'api.reports.performances.index',
                    path: '',
                    component: Performance,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Ogretmen Performans Raporlari',
                        apiPoint: 'reports/performances',
                    },
                }
            ]
        },
        {
            path: 'accountings',
            component: PageContainer,
            meta: {
                auth: true,
                layout: LayoutConfig.full,
                title: 'Muhasebe Programi Raporlari',
                breadcrumb: 'api.reports.accountings.index',
            },
            children: [
                {
                    name: 'api.reports.accountings.index',
                    path: '',
                    component: DatatableContainer,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Muhasebe Programi Raporlari',
                        apiPoint: 'reports/accountings',
                    },
                },
                {
                    name: 'api.reports.accountings.create',
                    path: 'create',
                    component: CreateAccounting,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Muhasebe Programi Raporlari',
                        apiPoint: 'reports/accountings',
                    },
                },
                {
                    name: 'api.reports.accountings.show',
                    path: ':id',
                    component: ShowAccounting,
                    meta: {
                        auth: true,
                        layout: LayoutConfig.full,
                        title: 'Muhasebe Programi Raporlari',
                        apiPoint: 'reports/accountings',
                    },
                }
            ]
        },
    ]
}
