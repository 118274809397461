<template>
    <div id="Sidebar" v-if="$route.meta.layout.header">
        <el-menu :collapse="true" default-active="/dashboard" @select="handleSelect" ref="SidebarMenu">
            <el-menu-item index="/dashboard" v-if="hasPermission('api.dashboard.index')">
                <icon icon="gauge" />
                <span slot="title">
                    Dashboard
                </span>
            </el-menu-item>
            <el-submenu index="/system" v-if="hasPermission('api.system.index')">
                <template slot="title">
                    <icon icon="cog" />
                    <span slot="title">Sistem</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Sistem</div>
                    </template>
                    <el-menu-item index="/system/settings" v-if="hasPermission('api.system.settings.index')">
                        Ayarlar
                    </el-menu-item>
                    <el-menu-item index="/system/menus" v-if="hasPermission('api.system.menus.index')">
                        Menuler
                    </el-menu-item>
                    <el-menu-item index="/system/languages" v-if="hasPermission('api.system.languages.index')">
                        Diller
                    </el-menu-item>
                    <el-menu-item index="/system/currencies" v-if="hasPermission('api.system.currencies.index')">
                        Para Birimleri
                    </el-menu-item>
                    <el-submenu index="/system/definitions" v-if="hasPermission('api.system.definitions.index')">
                        <span slot="title">
                            Tanimlar
                        </span>
                        <el-menu-item index="/system/definitions/years">
                            Yillar
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/days">
                            Gunler
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/tax-classes">
                            Vergi Siniflari
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/length-classes">
                            Uzunluk Siniflari
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/weight-classes">
                            Agirlik Siniflari
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/genders">
                            Cinsiyet Tanimlari
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/educations">
                            Egitim
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/jobs">
                            Meslekler
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/feedback_subjects">
                            Geri Bildirim Konulari
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/event_types">
                            Etkinlik Turu
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/periods">
                            Donemler
                        </el-menu-item>
                        <el-menu-item index="/system/definitions/seasons">
                            Sezonlar
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/system/statuses" v-if="hasPermission('api.system.statuses.index')">
                        <span slot="title">
                            Durumlar
                        </span>
                        <el-menu-item index="/system/statuses/stocks">
                            Stok Durumlari
                        </el-menu-item>
                        <el-menu-item index="/system/statuses/orders">
                            Siparis Durumlari
                        </el-menu-item>
                        <el-menu-item index="/system/statuses/order-returns">
                            Iade Durumlari
                        </el-menu-item>
                        <el-menu-item index="/system/statuses/shippings">
                            Kargo Durumları
                        </el-menu-item>
                        <el-menu-item index="/system/statuses/badges">
                            Rozetler
                        </el-menu-item>
                        <el-menu-item index="/system/statuses/skills">
                            Yetenekler
                        </el-menu-item>
                        <el-menu-item index="/system/statuses/levels">
                            Seviyeler
                        </el-menu-item>
                        <el-menu-item index="/system/statuses/cards">
                            Card Etiketleri
                        </el-menu-item>

                    </el-submenu>
                    <el-submenu index="/system/zones" v-if="hasPermission('api.system.zones.index')">
                        <span slot="title">
                            Bolgeler
                        </span>
                        <el-menu-item index="/system/zones/countries">
                            Ulkeler
                        </el-menu-item>
                        <el-menu-item index="/system/zones/cities">
                            Sehirler
                        </el-menu-item>
                        <el-menu-item index="/system/zones/towns">
                            Ilceler
                        </el-menu-item>
                        <el-menu-item index="/system/zones/districts">
                            Semtler
                        </el-menu-item>
                        <el-menu-item index="/system/zones/neighborhoods">
                            Mahalleler
                        </el-menu-item>
                    </el-submenu>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/tools" v-if="hasPermission('api.tools.index')">
                <template slot="title">
                    <icon icon="tools" />
                    <span slot="title">Araçlar</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Araçlar</div>
                    </template>
                    <el-submenu index="/tools/logs" v-if="hasPermission('api.tools.logs.index')">
                        <span slot="title">
                            Logs
                        </span>
                        <el-menu-item index="/tools/logs/processes"
                                      v-if="hasPermission('api.tools.logs.processes.index')">
                            Process Logs
                        </el-menu-item>
                        <el-menu-item index="/tools/logs/requests" v-if="hasPermission('api.tools.logs.requests.index')">
                            Request Logs
                        </el-menu-item>
                        <el-menu-item index="/tools/logs/education-notifications" v-if="hasPermission('api.tools.logs.education_notifications.index')">
                            Education Notification Logs
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="/tools/files" v-if="hasPermission('api.tools.files.index')">
                        Dosyalar
                    </el-menu-item>
                    <el-menu-item index="/tools/notes" v-if="hasPermission('api.tools.notes.index')">
                        Notlar
                    </el-menu-item>
                    <el-menu-item index="/tools/messages" v-if="hasPermission('api.tools.messages.index')">
                        Ozel Mesajlar
                    </el-menu-item>
                    <el-menu-item index="/tools/exports" v-if="hasPermission('api.tools.exports.index')">
                        Excel Ciktilari
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/crm" v-if="hasPermission('api.crm.index')">
                <template slot="title">
                    <icon icon="account-group" />
                    <span slot="title">Crm</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Müşteri Yönetimi</div>
                    </template>
                    <el-menu-item index="/crm/users" v-if="hasPermission('api.crm.users.index')">
                        Kullanicilar
                    </el-menu-item>
                    <el-menu-item index="/crm/students" v-if="hasPermission('api.crm.students.index')">
                        Ogrenciler
                    </el-menu-item>
                    <el-menu-item index="/crm/addresses" v-if="hasPermission('api.crm.addresses.index')">
                        Adresler
                    </el-menu-item>
                    <el-menu-item index="/crm/roles" v-if="hasPermission('api.crm.roles.index')">
                        Roller
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/cms" v-if="hasPermission('api.cms.index')">
                <template slot="title">
                    <icon icon="file-document-edit-outline" />
                    <span slot="title">Cms</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">İçerik Yönetimi</div>
                    </template>
                    <el-submenu index="/cms/pages" v-if="hasPermission('api.cms.pages.index')">
                        <span slot="title">
                            Sayfalar
                        </span>
                        <el-menu-item index="/cms/categories/pages"
                                      v-if="hasPermission('api.cms.categories.pages.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/pages" v-if="hasPermission('api.cms.contents.pages.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/posts" v-if="hasPermission('api.cms.posts.index')">
                        <span slot="title">
                            Blog
                        </span>
                        <el-menu-item index="/cms/categories/posts"
                                      v-if="hasPermission('api.cms.categories.pages.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/posts" v-if="hasPermission('api.cms.contents.pages.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/faqs" v-if="hasPermission('api.cms.faqs.index')">
                        <span slot="title">Sikca Sorulan Sorular</span>
                        <el-menu-item index="/cms/categories/faqs"
                                      v-if="hasPermission('api.cms.categories.faqs.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/faqs" v-if="hasPermission('api.cms.contents.faqs.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/references" v-if="hasPermission('api.cms.references.index')">
                        <span slot="title">Referanslar</span>
                        <el-menu-item index="/cms/categories/references"
                                      v-if="hasPermission('api.cms.categories.references.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/references"
                                      v-if="hasPermission('api.cms.contents.references.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/campaigns" v-if="hasPermission('api.cms.campaigns.index')">
                        <span slot="title">Kampanyalar</span>
                        <el-menu-item index="/cms/categories/campaigns"
                                      v-if="hasPermission('api.cms.categories.campaigns.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/campaigns"
                                      v-if="hasPermission('api.cms.contents.campaigns.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/branches" v-if="hasPermission('api.cms.branches.index')">
                        <span slot="title">Subeler</span>
                        <el-menu-item index="/cms/categories/branches"
                                      v-if="hasPermission('api.cms.categories.branches.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/branches"
                                      v-if="hasPermission('api.cms.contents.branches.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/careers" v-if="hasPermission('api.cms.careers.index')">
                        <span slot="title">Kariyer</span>
                        <el-menu-item index="/cms/categories/careers"
                                      v-if="hasPermission('api.cms.categories.careers.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/careers"
                                      v-if="hasPermission('api.cms.contents.careers.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/exam_promos" v-if="hasPermission('api.cms.exam_promos.index')">
                        <span slot="title">Sinav Tanitim Sayfalari</span>
                        <el-menu-item index="/cms/categories/exam_promos" v-if="hasPermission('api.cms.categories.exam_promos.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/exam_promos" v-if="hasPermission('api.cms.contents.exam_promos.index')">
                            Sayfalar
                        </el-menu-item>
                        <el-menu-item index="/educations/exams/announcements" v-if="hasPermission('api.educations.exams.announcements.index')">
                            Duyurular
                        </el-menu-item>
                        <el-menu-item index="/educations/exams/events" v-if="hasPermission('api.educations.exams.events.index')">
                            Takvim
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/city_promos" v-if="hasPermission('api.cms.city_promos.index')">
                        <span slot="title">Sehir Tanitim Sayfalari</span>
                        <el-menu-item index="/cms/categories/city_promos"
                                      v-if="hasPermission('api.cms.categories.city_promos.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/city_promos"
                                      v-if="hasPermission('api.cms.contents.city_promos.index')">
                            Sayfalar
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/encyclopedia" v-if="hasPermission('api.cms.forums.index')">
                        <span slot="title">Ansiklopedi</span>
                        <el-menu-item index="/cms/categories/encyclopedia" v-if="hasPermission('api.cms.categories.encyclopedia.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/encyclopedia" v-if="hasPermission('api.cms.contents.encyclopedia.index')">
                            Sayfalar
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/cms/forums" v-if="hasPermission('api.cms.forums.index')">
                        <span slot="title">Forumlar</span>
                        <el-menu-item index="/cms/categories/forums" v-if="hasPermission('api.cms.categories.forums.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/cms/contents/forums" v-if="hasPermission('api.cms.contents.forums.index')">
                            Sayfalar
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="/cms/features" v-if="hasPermission('api.cms.features.index')">
                        Ozellikler
                    </el-menu-item>
                    <el-menu-item index="/cms/comments" v-if="hasPermission('api.cms.comments.index')">
                        Yorumlar
                    </el-menu-item>
                    <el-menu-item index="/cms/feedbacks" v-if="hasPermission('api.cms.feedbacks.index')">
                        Geri Bildirimler
                    </el-menu-item>
                    <el-submenu index="/tools/seo" v-if="hasPermission('api.tools.seo.index')">
                        <span slot="title">
                            Seo
                        </span>
                        <el-menu-item index="/tools/seo/files" v-if="hasPermission('api.tools.seo.files.index')">
                            Dosyalar
                        </el-menu-item>
                        <el-menu-item index="/tools/seo/redirects"
                                      v-if="hasPermission('api.tools.seo.redirects.index')">
                            Yonlendirmeler
                        </el-menu-item>
                        <el-menu-item index="/tools/seo/links" v-if="hasPermission('api.tools.seo.links.index')">
                            Linkler
                        </el-menu-item>
                    </el-submenu>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/catalog" v-if="hasPermission('api.catalog.index')">
                <template slot="title">
                    <icon icon="cart-outline" />
                    <span slot="title">Katalog</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Katalog</div>
                    </template>
                    <el-submenu index="/catalog/products" v-if="hasPermission('api.catalog.products.index')">
                        <span slot="title">Urunler</span>
                        <el-menu-item index="/catalog/categories/products" v-if="hasPermission('api.catalog.categories.products.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/catalog/products/groups" v-if="hasPermission('api.catalog.contents.products.groups.index')">
                            Gruplar
                        </el-menu-item>
                        <el-menu-item index="/catalog/products/packages" v-if="hasPermission('api.catalog.contents.products.packages.index')">
                            Calisma Paketleri
                        </el-menu-item>
                        <el-menu-item index="/catalog/products/digitals" v-if="hasPermission('api.catalog.contents.products.digitals.index')">
                            Dijital Urunler
                        </el-menu-item>
                        <el-menu-item index="/catalog/products/physicals" v-if="hasPermission('api.catalog.contents.products.physicals.index')">
                            Fiziksel Urunler
                        </el-menu-item>
                        <el-menu-item index="/catalog/products/events" v-if="hasPermission('api.catalog.contents.products.events.index')">
                            Etkinlik / Webinar Urunleri
                        </el-menu-item>
                        <el-menu-item index="/catalog/products/subscriptions" v-if="hasPermission('api.catalog.contents.products.subscriptions.index')">
                            Paketler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/catalog/materials" v-if="hasPermission('api.catalog.materials.index')">
                        <span slot="title">Ucretsiz Kaynaklar</span>
                        <el-menu-item index="/catalog/categories/materials"
                                      v-if="hasPermission('api.catalog.categories.materials.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/catalog/contents/materials"
                                      v-if="hasPermission('api.catalog.contents.materials.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/catalog/teachers" v-if="hasPermission('api.catalog.teachers.index')">
                        <span slot="title">Egitmenler</span>
                        <el-menu-item index="/catalog/categories/teachers"
                                      v-if="hasPermission('api.catalog.categories.teachers.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/catalog/contents/teachers"
                                      v-if="hasPermission('api.catalog.contents.teachers.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="/catalog/product-landing-pages" v-if="hasPermission('api.catalog.product_landing_pages.index')">
                        <span slot="title">Tanitim Sayfalari</span>
                        <el-menu-item index="/catalog/categories/product-landing-pages" v-if="hasPermission('api.catalog.categories.product_landing_pages.index')">
                            Kategoriler
                        </el-menu-item>
                        <el-menu-item index="/catalog/contents/product-landing-pages" v-if="hasPermission('api.catalog.contents.product_landing_pages.index')">
                            Icerikler
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="/catalog/brands" v-if="hasPermission('api.catalog.brands.index')">
                        Markalar
                    </el-menu-item>
                    <el-menu-item index="/catalog/features" v-if="hasPermission('api.catalog.features.index')">
                        Ozellikler
                    </el-menu-item>
                    <el-menu-item index="/catalog/comments" v-if="hasPermission('api.catalog.comments.index')">
                        Yorumlar
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/marketing" v-if="hasPermission('api.marketing.index')">
                <template slot="title">
                    <icon icon="sale" />
                    <span slot="title">Pazarlama</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Pazarlama</div>
                    </template>
                    <el-menu-item index="/marketing/newsletters"
                                  v-if="hasPermission('api.marketing.newsletters.index')">
                        Abonelikler
                    </el-menu-item>
                    <el-menu-item index="/marketing/campaigns" v-if="hasPermission('api.marketing.campaigns.index')">
                        Kampanyalar
                    </el-menu-item>
                    <el-menu-item index="/marketing/coupons" v-if="hasPermission('api.marketing.campaigns.index')">
                        Kupon Kodlari
                    </el-menu-item>
                    <el-menu-item index="/marketing/access-codes" v-if="hasPermission('api.marketing.access_codes.index')">
                        Erisim Kodlari
                    </el-menu-item>
                    <el-menu-item index="/marketing/gifts" v-if="hasPermission('api.marketing.gifts.index')">
                        Hediyeler
                    </el-menu-item>
                    <el-menu-item index="/marketing/banners" v-if="hasPermission('api.marketing.banners.index')">
                        Reklamlar
                    </el-menu-item>
                    <el-menu-item index="/marketing/drafts" v-if="hasPermission('api.marketing.drafts.index')">
                        Mesaj Sablonlari
                    </el-menu-item>
                    <el-menu-item index="/marketing/bulk-messages" v-if="hasPermission('api.marketing.bulk_messages.index')">
                        Toplu Mesaj Gonderimi
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/shop" v-if="hasPermission('api.shop.index')">
                <template slot="title">
                    <icon icon="dolly" />
                    <span slot="title">Satış</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Satış</div>
                    </template>
                    <el-menu-item index="/shop/baskets" v-if="hasPermission('api.shop.baskets.index')">
                        Sepetler
                    </el-menu-item>
                    <el-menu-item index="/shop/orders" v-if="hasPermission('api.shop.orders.index')">
                        Siparisler
                    </el-menu-item>
                    <el-menu-item index="/shop/deliveries" v-if="hasPermission('api.shop.deliveries.index')">
                        Teslimatlar
                    </el-menu-item>
                    <el-menu-item index="/shop/print-monitors" v-if="hasPermission('api.shop.print_monitors.index')">
                        Baskı Monitörü
                    </el-menu-item>
                    <el-menu-item index="/shop/returns" v-if="hasPermission('api.shop.order_returns.index')">
                        Iadeler
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/reports" v-if="hasPermission('api.reports.index')">
                <template slot="title">
                    <icon icon="chart-areaspline" />
                    <span slot="title">Raporlar</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Raporlar</div>
                    </template>
                    <el-menu-item index="/reports/individual-assessment-reports" v-if="hasPermission('api.reports.performances.index')">
                        Bireysel Değerlendirme Raporları
                    </el-menu-item>
                    <el-menu-item index="/reports/performances" v-if="hasPermission('api.reports.performances.index')">
                        Ogretmen Performans
                    </el-menu-item>
                    <el-menu-item index="/reports/teachers" v-if="hasPermission('api.reports.teachers.index')">
                        Ogretmen
                    </el-menu-item>
                    <el-menu-item index="/reports/orders" v-if="hasPermission('api.reports.orders.index')">
                        Siparis
                    </el-menu-item>
                    <el-menu-item index="/reports/products" v-if="hasPermission('api.reports.products.index')">
                        Urun
                    </el-menu-item>
                    <el-menu-item index="/reports/customers" v-if="hasPermission('api.reports.customers.index')">
                        Musteri
                    </el-menu-item>
                    <el-menu-item index="/reports/accountings" v-if="hasPermission('api.reports.accountings.index')">
                        Muhasebe Programi
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/educations" v-if="hasPermission('api.educations.index')">
                <template slot="title">
                    <icon icon="school-outline" />
                    <span slot="title">Egitim</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Egitim</div>
                    </template>
                    <el-submenu index="/catalog/product-landing-pages" v-if="hasPermission('api.educations.lessons.index')">
                        <span slot="title">Ders Yonetimi</span>
                        <el-menu-item index="/educations/lessons/lessons" v-if="hasPermission('api.educations.lessons.index')">
                            Dersler
                        </el-menu-item>
                        <el-menu-item index="/educations/lessons/try" v-if="hasPermission('api.educations.lessons.index')">
                            Deneme Dersi Olustur
                        </el-menu-item>
                        <el-menu-item index="/educations/lessons/lesson-management" v-if="hasPermission('api.educations.lessons.index')">
                            Toplu Ders Islemleri
                        </el-menu-item>
                        <el-menu-item index="/educations/lessons/lesson-records" v-if="hasPermission('api.educations.lessons.index')">
                            Video Kayitlari
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="/educations/books/contents" v-if="hasPermission('api.educations.books.contents.index')">
                        Kitaplar
                    </el-menu-item>
                    <el-menu-item index="/educations/readings" v-if="hasPermission('api.educations.readings.index')">
                        Okuma Parçaları
                    </el-menu-item>
                    <el-menu-item index="/educations/tests" v-if="hasPermission('api.educations.readings.index')">
                        Testler
                    </el-menu-item>
                    <el-menu-item index="/educations/videos" v-if="hasPermission('api.educations.videos.index')">
                        Videolar
                    </el-menu-item>
                    <el-menu-item index="/educations/listenings" v-if="hasPermission('api.educations.listenings.index')">
                        Dinlemeler
                    </el-menu-item>
                    <el-menu-item index="/educations/documents" v-if="hasPermission('api.educations.documents.index')">
                        Dokümanlar
                    </el-menu-item>
                    <el-menu-item index="/educations/word-lists" v-if="hasPermission('api.educations.word_lists.index')">
                        Kelime Listeleri
                    </el-menu-item>
                    <el-menu-item index="/educations/homeworks" v-if="hasPermission('api.educations.homeworks.index')">
                        Odevler
                    </el-menu-item>
                    <el-menu-item index="/educations/individual-assessment-reports/items" v-if="hasPermission('api.educations.individual_assessment_reports.index')">
                        Bireysel Değerlendirme Raporları
                    </el-menu-item>
                    <el-menu-item index="/educations/information-letters" v-if="hasPermission('api.educations.information_letters.index')">
                        Bilgilendirme Mektuplari
                    </el-menu-item>
                    <el-menu-item index="/educations/feedbacks">
                        Ogretmen Geri Bildirimleri
                    </el-menu-item>
                    <el-menu-item index="/educations/content-share" v-if="hasPermission('api.educations.content_share.index')">
                        Icerik Dagitimi
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/archives" v-if="hasPermission('api.archives.index')">
                <template slot="title">
                    <icon icon="archive-outline" />
                    <span slot="title">Arşiv</span>
                </template>
                <el-menu-item-group class="sidebar-menu">
                    <template slot="title">
                        <div class="inner-title">Arşiv</div>
                    </template>
                    <el-menu-item index="/archives/question-contents" v-if="hasPermission('api.archives.question_contents.index')">
                        Sorular
                    </el-menu-item>
                    <el-menu-item index="/archives/words" v-if="hasPermission('api.archives.words.index')">
                        Kelimeler
                    </el-menu-item>
                    <el-menu-item index="/archives/sentences" v-if="hasPermission('api.archives.sentences.index')">
                        Cumleler
                    </el-menu-item>
                    <el-submenu index="/educations/tags" class="top-fix">
                        <span slot="title">Etiketler</span>
                        <el-menu-item index="/archives/knowledges" v-if="hasPermission('api.archives.knowledges.index')">
                            Bilgi Havuzu
                        </el-menu-item>
                        <el-menu-item index="/archives/topics" v-if="hasPermission('api.archives.topics.index')">
                            Konu Etiketleri
                        </el-menu-item>
                        <el-menu-item index="/archives/examinations" v-if="hasPermission('api.archives.examinations.index')">
                            Sınavlar
                        </el-menu-item>
                        <el-menu-item index="/archives/audiences" v-if="hasPermission('api.archives.audiences.index')">
                            Hedef Kitle
                        </el-menu-item>
                        <el-menu-item index="/archives/levels" v-if="hasPermission('api.archives.levels.index')">
                            Seviyeler
                        </el-menu-item>
                        <el-menu-item index="/archives/branches" v-if="hasPermission('api.archives.branches.index')">
                            Branşlar
                        </el-menu-item>
                    </el-submenu>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    methods: {
        handleSelect(index) {
            this.goRoute(index)
        }
    }
}
</script>
