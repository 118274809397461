<template>
    <div v-loading="pageLoading">
        <el-card class="card-flat-list overflow-400">
            <template slot="header">
                <div class="title">Bireysel Değerlendirme Raporları</div>
                <div class="actions">
                    <el-tooltip content="Yenile" placement="left">
                        <el-button class="flat dark purple" icon="el-icon-refresh" @click="index"/>
                    </el-tooltip>
                    <el-tooltip content="Ekle" placement="left">
                        <el-button class="flat dark blue" icon="el-icon-plus" @click="create"/>
                    </el-tooltip>
                </div>
            </template>
            <div class="card-flat-list-items sm" v-scroll:throttle="{ throttle: 500 }">
                <div class="item" v-for="item in items" :key="item.id">
                    <div class="text cursor-pointer">
                        <div class="title" style="margin: 0" @click="edit(item.id)">{{ item.title }}</div>
                    </div>
                    <div class="actions" style="right: 20px; display: flex; opacity: 1">
                        <a class="el-button flat dark blue el-button--default" :href="'https://' + $store.state.ui.tenant.domain + '/exports/users/individual-assessment-reports/' + item.id" target="_blank">
                            <i class="el-icon-document"></i>
                        </a>
                        <el-button class="flat dark red" icon="el-icon-delete" @click="destroy(item.id)"/>
                    </div>
                </div>
            </div>
        </el-card>

        <el-dialog title="Bireysel Değerlendirme Raporu" :visible.sync="dialogVisible" v-loading="pageLoading"
                   width="90%">
            <el-form :label-position="'top'" ref="form" :model="form">
                <el-form-item label="Raporlar" v-if="!form.id">
                    <el-select v-model="form.individual_assessment_report_id" placeholder="Raporlar">
                        <el-option v-for="item in reports" :key="item.id" :label="item.title" :value="item.id"/>
                    </el-select>
                </el-form-item>

                <template v-if="form.id">

                    <el-form-item label="Öğretmen Notu">
                        <el-input v-model="form.note" placeholder="Öğretmen Notu" type="textarea" rows="6"/>
                    </el-form-item>

                    <div class="individual-assessment-reports">
                        <template v-for="group in report.groups">
                            <div class="group">
                                <div class="head">
                                    <div class="title">
                                        <div class="main">{{ group.title }}</div>
                                        <div class="sub">{{ group.translation }}</div>
                                    </div>
                                    <div class="answers">
                                        <template v-for="answer in group.answers">
                                            <div class="column">
                                                <div class="main">{{ answer.title }}</div>
                                                <div class="sub">{{ answer.translation }}</div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="body">
                                    <template v-for="question in group.questions">
                                        <div class="question">
                                            <div class="title">
                                                <div class="main">{{ question.title }}</div>
                                                <div class="sub">{{ question.translation }}</div>
                                            </div>
                                            <div class="answers">
                                                <template v-for="answer in group.answers">
                                                    <div class="column">
                                                        <el-button
                                                            class="dark"
                                                            icon="el-icon-check"
                                                            :class="{green : question.answer_id === answer.id, 'flat black' : question.answer_id !== answer.id}"
                                                            @click="question.answer_id = answer.id"
                                                        />
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </el-form>

            <template slot="footer" class="dialog-footer">
                <el-button class="fluid flat dark green large" @click="store" v-if="!form.id">
                    Kaydet
                </el-button>
                <el-button class="fluid flat dark green large" @click="update" v-if="form.id">
                    Guncelle
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Branches',
    props: ['id'],
    data() {
        return {
            pageLoading: false,
            dialogVisible: false,
            form: {
                individual_assessment_report_id: null,
                title: null,
                description: null,
                note: null,
                results: [],
            },

            items: [],
            reports: [],
            report: {
                id: null,
                groups: []
            }
        }
    },
    mounted() {
        this.getReports();
        this.index();
    },
    methods: {
        getReports() {
            this.pageLoading = true;

            this.$http
                .get('educations/individual-assessment-reports/items', {params: {perPage: 10000}})
                .then(response => {
                    this.pageLoading = false;
                    this.reports = response.body.rows;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Generate api point
         */
        endPoint(suffix) {

            const path = 'crm/users/' + this.id + '/individual-assessment-reports';

            if (suffix) {
                return path + '/' + suffix;
            }

            return path;
        },

        /**
         * All items
         */
        index() {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(), {params: {perPage: 1000}})
                .then(response => {

                    this.items = response.body.rows;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         *  Create modal
         */
        create() {
            this.form = {
                results: []
            };
            this.report = {};
            this.dialogVisible = true;
        },

        /**
         * Edit modal
         */
        edit(id) {
            this.form = {};
            this.dialogVisible = true;

            this.show(id);
        },

        /**
         * Show page
         */
        show(id) {
            this.pageLoading = true;

            this.$http
                .get(this.endPoint(id))
                .then(response => {
                    this.form = response.body.data;
                    this.report = response.body.relations.report.data;

                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form save
         */
        store() {
            this.pageLoading = true;

            this.$http
                .post(this.endPoint(), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Form update
         */
        update() {
            this.pageLoading = true;

            this.lodash.forEach(this.report.groups, (group) => {
                this.lodash.forEach(group.questions, (question) => {
                    this.form.results.push({
                        individual_assessment_report_group_question_id: question.id,
                        individual_assessment_report_group_answer_id: question.answer_id,
                    })
                })
            })

            this.$http
                .put(this.endPoint(this.form.id), this.form)
                .then(response => {
                    this.pageLoading = false;
                    this.dialogVisible = false;

                    this.index();

                    return this.responseSuccess(response);
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },

        /**
         * Destroy item
         */
        destroy(id) {

            this
                .$confirm('Verileriniz kalici olarak siliniyor. Onayliyor musunuz?', 'Uyari', {
                    confirmButtonText: 'Onayla',
                    cancelButtonText: 'Iptal',
                    type: 'warning'
                })
                .then(() => {

                    this.$http
                        .delete(this.endPoint(id))
                        .then(response => {

                            this.index();

                            this.responseSuccess(response);

                        })
                        .catch(response => {
                            this.responseError(response);
                        });


                    return true;

                })
                .catch((response) => {
                    this.$notify({
                        type: 'success',
                        message: 'Isleminiz basarili bir sekilde iptal edildi!'
                    });

                    return false;
                });
        }
    }
}
</script>
