<template>
    <div v-loading="pageLoading">
        <el-card class="custom-padding">
            <template slot="header">
                <div class="crud-header">
                    <div class="title">{{ cardCrudTitle() }}</div>
                    <div class="actions">
                        <el-tooltip content="Filtrele" placement="bottom">
                            <el-button class="flat dark secondary" icon="el-icon-search" @click="index"/>
                        </el-tooltip>
                    </div>
                </div>
            </template>
            <div class="el-card__filters">
                <el-form :label-position="'top'" ref="filter" :model="filter">
                    <el-form-item class="margin-bottom-0">
                        <user :container="filter" model="teacher_id" placeholder="Öğretmen"/>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <template v-for="row in response.rows">
            <el-card class="custom-padding">
                <template slot="header">
                    <div class="crud-header">
                        <div class="title">{{ row.title }}</div>
                        <div class="actions">
                            {{ row.report_count }} / {{ row.student_count }}
                        </div>
                    </div>
                </template>
                <div class="box-list">
                    <template v-for="student in row.students">
                        <div class="box-list-item">
                            <div class="name">{{ student.name }}</div>
                            <div class="description">
                                <div v-if="student.id">
                                    <a :href="'https://' + $store.state.ui.tenant.domain + '/exports/users/individual-assessment-reports/' + student.id" target="_blank">
                                        {{ student.report }}
                                    </a>
                                </div>
                                <div v-if="!student.id">
                                    rapor yok
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </el-card>
        </template>
    </div>
</template>

<script>
import CrudHeader from "@/app/Components/CrudHeader";

export default {
    name: 'Customer',
    components: {
        CrudHeader
    },
    data() {
        return {
            pageLoading: false,
            filter: {
                teacher_id: ''
            },
            response: {
                rows: [],
                student_count: 0,
                report_count: 0
            },
        }
    },
    mounted() {
        //
    },
    methods: {
        index() {

            this.pageLoading = true;

            this.$http
                .get(this.$route.meta.apiPoint, {params: {teacher_id: this.filter.teacher_id}})
                .then(response => {
                    this.response = response.body.data;
                    this.pageLoading = false;
                })
                .catch(response => {
                    this.pageLoading = false;
                    return this.responseError(response);
                })
        },
    }
}
</script>
